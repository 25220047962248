
.card {
  width: 300px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 15px;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
}

.card-title {
  font-size: 15px;
  margin: 0;
}

.bullet-icon {
  font-size: large;
  color: #808080;
  padding-right: 2%;
}

.card-id {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #808080;
  justify-content: space-evenly;
}

.card-tag {
  font-style: italic;
  color: #808080;
  padding: 0.5rem;
  border: 0.01rem solid #d1d5d9;
}
img {
  height: 3rem;
  max-width: auto;
  border-radius: 50%;
  margin-left: 200px;
}
