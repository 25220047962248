
.DropDown {
  /* display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 20px; */
  align-items: baseline;
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.dropdown {
  margin-right: 20px;
}

label {
  font-weight: bold;
  margin-right: 5px;
}

select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
#me {
  border: 1px solid #d1d5d9;
  border-radius: 8px;
  padding: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.column-box {
  border: 1px solid #d1d5d9;
  border-radius: 6px;
  margin: 8px;
  padding: 3px;
  font-size: 13px;
  height: 11vh;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1000;
  top :50px;
}
